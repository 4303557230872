<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Profile", "active": true }
      ],
    "title": "Profile"
      }
}
</route>
<template>
  <div class="profile">
    <UserProfile />
  </div>
</template>

<script>
// @ is an alias to /src
import UserProfile from '@/modules/organisation/pages/UserProfile.vue';

export default {
  name: 'Profile',
  components: {
    UserProfile,
  },
  layout: 'AppMain',
};
</script>
